import React from "react"
import classes from "./PortfolioPage.module.scss"
import CardWrapper from "../CardWraper/CardWrapper"

const PortfolioPage = () => (
  <div className="container">
    <div className={classes.portfolioPage}>
      <h1 className={classes.header}> Portfolio </h1>
      <CardWrapper />
    </div>
  </div>
)

export default PortfolioPage
