import React from "react"
import classes from "./CardWrapper.module.scss"
import CardComponent from "./CardComponent/CardComponent"
import { useCasesQuery } from "../../hooks/useCasesQuery"

const CardWrapper = () => {
  const { nodes } = useCasesQuery()
  return (
    <div className={classes.cardWrapper}>
      {nodes.map(card => {
        return (
          <CardComponent
            imageSrc={card.screenPreview}
            description={card.previewText}
            title={card.title}
            link={`/portfolio/${card.name}`}
            key={card.id}
          />
        )
      })}
    </div>
  )
}

export default CardWrapper
