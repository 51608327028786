import React from "react"
import Image from "src/components/image"
import classes from "./CardComponent.module.scss"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const CardComponent = ({ imageSrc, title, description, link, isForSlider }) => (
  <>
    <Link
      to={link}
      id={"headerPortfolioCase" + title.replace(/\s/g, "")}
      className={classes.cardLink}
    >
      <div>
        <Image src={imageSrc} className={classes.cardImage} />
      </div>
      {isForSlider && (
        <div className={classes.desktopContent}>
          <div>
            <h4 className={classes.desktopTitle}>{title} </h4>
            <p className={classes.desktopText}> {description} </p>
          </div>
          <div>
            <span className={classes.desktopTag}>PHP</span>
            <span className={classes.desktopTag}>Laravel</span>
            <span className={classes.desktopTag}>MySQL</span>
          </div>
        </div>
      )}
      <div className={isForSlider ? classes.hideContentOnMobile : undefined}>
        <h4 className={classes.cardTitle}> {title} </h4>
        <p className={classes.cardDescription}> {description} </p>
        <span className={classes.cardTag}> Web Application </span>
      </div>
    </Link>
  </>
)

CardComponent.propTypes = {
  isForSlider: PropTypes.bool,
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
}

CardComponent.defaultProps = {
  isForSlider: false,
}

export default CardComponent
