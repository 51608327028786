import React from "react"
import BasicLayout from "../components/layout/BasicLayout/BasicLayout"
import SEO from "../components/seo"
import PortfolioPage from "../components/PortfolioPage/PortfolioPage"
import TickerRun from "../components/layout/TickerRun/TickerRun"

const Portfolio = () => (
  <BasicLayout stickyBtn={false}>
    <SEO title="Portfolio" />
    <PortfolioPage />
    <TickerRun text="Get in Touch" stringTicker yellow={false} />
  </BasicLayout>
)

export default Portfolio
